<template>
  <component :is="layoutComponent" :show-selected-station="showSelectedStation">
    <router-view :key="$route.fullPath" />
  </component>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import AsomLayout from "./layout/AsomLayout";

export default {
  components: {
    AsomLayout,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
    }),
    allowsAnonymous() {
      return this.$route.meta && this.$route.meta.allowsAnonymous;
    },
    layoutComponent() {
      if (!this.allowsAnonymous) {
        return "asom-layout";
      }
      return "div";
    },
    showSelectedStation() {
      return (
        this.isAuthenticated &&
        (this.$route.path.indexOf("/cash-mgnt") === 0 ||
          this.$route.path.indexOf("/inventory") === 0 ||
          this.$route.path.indexOf("/fault-mgnt") === 0 ||
          this.$route.path.indexOf("/shift-handover") === 0 ||
          this.$route.path.indexOf("/wayfinding") === 0)
      );
    },
  },
  watch: {
    $route(to) {
      let title = "ASOMS";
      let metaTitle = get(to, "meta.title");
      if (metaTitle) title = this.$t(metaTitle);
      document.title = title;
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.subheader {
  border-left: 4px solid theme("colors.gray.800");
  padding-left: 10px;
  font-weight: theme("fontWeight.semibold");
}
</style>
